import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTopButton from './constants/ScrollToTop';
import { AboutPage, ContactPage, JournalsPage, HomePage } from './scenes';
import { JournalPage, JournalCurrentPage, JournalVolume, ArchiveIssuePage, } from './pages';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/journals' element={<JournalsPage />} />
        <Route path='/contact' element={<ContactPage />} />

        <Route path='/journals/:slug' element={<JournalPage />} />
        <Route path='/journals/:slug/:currentSlug' element={<JournalCurrentPage />} />
        <Route path='/journals/:slug/archives/:volumeSlug' element={<JournalVolume />} />
        <Route path='/journals/:slug/archives/:volumeSlug/:itemSlug' element={<ArchiveIssuePage />} />
      </Routes>

      <ScrollToTopButton />
    </BrowserRouter>
  );
};

export default App;