import { 
  NavbarAlt2,
  Hero3,
  JournalsMain,
  Footer 
} from "../components";

import { Helmet } from "react-helmet";
import { hero2 } from "../assets";
  
  const JournalsPage = () => {

    return (
      <div className="font-instrument-sans">
        <Helmet>
          <title>Journals | Lifeline Journals</title>
          <meta name="description" content="Join a community dedicated to advancing science, enhancing healthcare, and making a lasting impact on the world." />
        </Helmet>

        <div>
          <NavbarAlt2 />
          <div className='relative flex items-center justify-center w-full 
          md:max-h-[300px] ss:max-h-[300px] max-h-[200px] md:mt-20 ss:mt-16 
          mt-16'>
            <img src={hero2} alt='hero3'
            className='w-[120rem] md:h-[200px] ss:h-[150px]
            h-[120px]' />
            <div className='absolute w-full md:pb-8 ss:pb-8 pb-5'>
              <Hero3 />
            </div>
          </div>

          <div className='aboutMain'>
            <JournalsMain />
          </div>
          
          <div className='footer'>
            <Footer />
          </div>
        </div>
      </div>
    );
  };
  
  export default JournalsPage;