
const ItemCard = ({ item, archive, journal }) => {
  return (
    <a href={`/journals/${journal.slug.current}/archives/${archive.slug.current}/${item.slug.current}`}>
      <div className='w-full cursor-pointer hover:shadow-md rounded-md 
      bg-white border-main2 border-[1px] md:py-4 ss:py-4 flex items-center
      justify-center py-3'
      >
        <p className="md:text-[17px] ss:text-[14px] text-[13px] font-medium
        text-textalt">
          {item.name}
        </p>
      </div>
    </a>
  );
};


const Issues = ({ issue, journal, archive }) => {

  return (
    <section className="flex items-center md:px-0 ss:px-16 px-6 md:pt-8
    pt-10">
      <div className='items-center w-full flex'>
        <div className='grid md:grid-cols-2 ss:grid-cols-2 grid-cols-3 
        md:gap-8 ss:gap-6 gap-3 w-full'>
            {issue?.map((item) => (
              <ItemCard
                key={item.name}
                item={item}
                {...item}
                journal={journal}
                archive={archive}
              />
            ))}
        </div>
      </div>
    </section>
  )
};

export default Issues;