import styles from '../styles';
import { motion } from 'framer-motion';
import { textVariant } from '../utils/motion';
import { SectionWrapper } from '../hoc';

const Commitment = () => {
  return (
    <section className='w-full md:min-h-[350px] ss:min-h-[350px] 
    min-h-[350px] flex'>
        <div className='w-full flex justify-center'>
            <motion.div variants={textVariant()}
            className={`${styles.paddingX} flex flex-col justify-center 
            items-center`}>
                <h1 className='text-primary font-bold md:text-[40px]
                ss:text-[35px] text-[25px]'>
                    Our Mission
                </h1>
                
                <div className='flex relative justify-center'>
                    <div className='bg-secondary md:w-[120px] ss:w-[120px]
                w-[80px] h-[3px]' />
                </div>

                <motion.div className='flex md:mt-12 ss:mt-10 mt-8 flex-col
                items-center justify-center'>
                    <p className='text-main md:text-[17px] ss:text-[16px] 
                    text-[13px] max-w-[700px] text-center md:leading-[27px] 
                    ss:leading-[28px] leading-[20px]'>
                       Our goal is to support and amplify the voices of 
                       researchers, educators, and professionals, 
                       providing them with a trusted platform to share 
                       their work with the global community. We pledge 
                       to continuously advance the accessibility and 
                       impact of knowledge, fostering a culture of 
                       learning and innovation that benefits society as 
                       a whole.
                    </p>

                    <p className='text-main md:text-[17px] ss:text-[16px] 
                    text-[13px] max-w-[700px] text-center md:leading-[28px] 
                    ss:leading-[28px] leading-[20px] mt-4'>
                        Join us in our mission to connect the dots 
                        between research and real-world applications, 
                        ensuring that knowledge reaches those who can 
                        make the most of it.
                    </p>
                </motion.div>
            </motion.div>
        </div>
    </section>
  )
};

export default SectionWrapper(Commitment, '');