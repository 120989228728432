import {
    Navbar, Hero, About, Footer, Journals
} from '../components';
import { useState, useEffect } from 'react';
import { heroImages, heroImagesMobile } from '../assets';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1060);
    const [loaded, setLoaded] = useState(false);
    const [heroHeight, setHeroHeight] = useState('80vh');
    const images = isMobile ? heroImagesMobile : heroImages;
  
    useEffect(() => {
      const preloadImages = (srcArray) => {
        const promises = srcArray.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
          });
        });
  
        Promise.all(promises)
          .then(() => setLoaded(true))
          .catch(() => console.error("Failed to preload images"));
      };
  
      preloadImages(images);
    }, [images]);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);
  
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1060);
        if (isMobile) {
          setHeroHeight('80vh');
        } else {
          setHeroHeight('80vh');
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => {
        clearInterval(intervalId);
        window.removeEventListener('resize', handleResize);
      };
    }, [images.length, isMobile]);

    return (
        <div className='font-instrument-sans'>
            <Helmet>
              <title>Lifeline Journals | Advancing Knowledge, Shaping the Future</title>
              <meta name="description" content="Join a community dedicated to advancing science, enhancing healthcare, and making a lasting impact on the world." />
            </Helmet>

            <Navbar />

            <div className={`md:mt-20 ss:mt-16 mt-16
            ${loaded ? 'opacity-100' : 'opacity-0 transition-opacity duration-500'}`}
            style={{
              backgroundImage: `url(${images[currentImageIndex]})`,
              objectFit: 'cover',
              backgroundPosition: isMobile ? 'bottom' : 'center',
              height: heroHeight,
              transition: 'background-image 1s ease-in-out',
            }}
            >
              <Hero />
            </div>

            <About />

            <div className='aboutMain'>
              <Journals />
            </div>

            <div className='footer'>
              <Footer />
            </div>
        </div>
    )
};

export default HomePage;