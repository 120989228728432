import { 
  NavbarAlt,
  Hero2,
  AboutMain,
  Commitment,
  Footer } from "../components";

import { hero2 } from "../assets";
import { Helmet } from "react-helmet";

const AboutPage = () => {

  return (
    <div className="font-instrument-sans">
      <Helmet>
        <title>About Us | Lifeline Journals</title>
        <meta name="description" content="Join a community dedicated to advancing science, enhancing healthcare, and making a lasting impact on the world." />
      </Helmet>

      <NavbarAlt />
      <div className='relative flex items-center justify-center w-full 
      md:max-h-[300px] ss:max-h-[300px] max-h-[200px] md:mt-20 ss:mt-16 
      mt-16'>
        <img src={hero2} alt='hero2'
        className='w-[120rem] md:h-[200px] ss:h-[150px]
        h-[120px]' />
        <div className='absolute w-full md:pb-8 ss:pb-8 pb-5'>
          <Hero2 />
        </div>
      </div>

      <div className='aboutMain'>
        <AboutMain />
      </div>

      <Commitment />

      <div className='footer'>
        <Footer />
      </div>
    </div>
  );
};

export default AboutPage;