import { 
    NavbarPages,
    HeroJournalCurrent,
    Sidebar,
    Sidebar2,
    Footer,
    CurrentPublications,
} 
from "../components";
    
import { hero3 } from "../assets";
import { Helmet } from "react-helmet";

import { useEffect, useState } from 'react';
import styles from "../styles";
import { useParams } from 'react-router-dom';
import { client } from '../sanity';
    
const JournalCurrentPage = () => {
    const { slug } = useParams();
    const [journal, setJournal] = useState(null);

    useEffect(() => {

        const query = `
          *[_type == "journals" && slug.current == $slug][0] {
            name,
            description,
            image,
            slug,
            issn,
            currentIssue->{
              name,
              slug,
              papers[]->{
                heading,
                authors,
                abstract,
                pages,
                pdfFile {
                  asset-> {
                    url
                  }
                }
              },
            },
            archives[]->{
              name,
              slug,
              issue[]->{
                name,
                slug,
                papers[]->{
                  heading,
                  authors,
                  abstract,
                  pages,
                  pdfFile {
                    asset-> {
                      url
                    }
                  }
                },
              },
            },
            indexings,
            editors[]->{
              name,
              school,
              profile
            },
            contact,
            frequency,
            instructions,
            processing,
          }
        `;
        client.fetch(query, { slug })
            .then((data) => setJournal(data))
            .catch((error) => console.error('Error fetching journal:', error));
        }, [slug]);
    
    if (!journal) {
        return <div>Loading...</div>;
    }
  
      return (
        <div className="font-instrument-sans">
          <Helmet>
            <title>{`${journal.name} | ${journal.currentIssue.name} | Lifeline Journals`}</title>
            <meta name="description" content="Join a community dedicated to advancing science, enhancing healthcare, and making a lasting impact on the world." />
          </Helmet>
  
          <div>
            <NavbarPages />
            <div className='relative flex items-center justify-center w-full 
            md:max-h-[300px] ss:max-h-[300px] max-h-[200px] md:mt-20 ss:mt-16 
            mt-16'>
              <img src={hero3} alt='hero3'
              className='w-[120rem] md:h-[200px] ss:h-[150px]
              h-[120px]' />
              <div className='absolute w-full md:pb-8 ss:pb-8 pb-5'>
                <HeroJournalCurrent journal={journal}/>
              </div>
            </div>

            <div className='aboutMain'>
                <div className={`${styles.padding} max-w-[78rem] mx-auto flex 
                md:py-2 hidden md:flex`}>
                    <div className="mt-[30px] flex-1">
                        <Sidebar journal={journal}/>
                    </div>

                    <div className='md:ml-20'>
                        <CurrentPublications journal={journal} />
                    </div>
                </div>

                <div className='flex py-2 md:hidden flex flex-col'>
                    <div className=''>
                        <CurrentPublications journal={journal} />
                    </div>

                    <div className="">
                        <Sidebar2 journal={journal}/>
                    </div>
                </div>
            </div>
    
            <div className='footer'>
              <Footer />
            </div>
          </div>
        </div>
      );
    };
    
export default JournalCurrentPage;