import { SectionWrapper } from '../hoc';
import { layout } from '../styles';
import { motion } from 'framer-motion';
import { textVariant } from '../utils/motion';

const Hero = () => {

  return (
    <section className='relative w-full mobile'>
      <div className='items-center justify-between w-full flex flex-row 
      items-start gap-5 md:mt-28 ss:mt-40 mt-24'
      >
        <div className={`${layout.sectionInfo}`}>
          <motion.div variants={textVariant()}>
            <h1 className='text-white font-bold md:text-[60px]
            ss:text-[45px] text-[35px] md:leading-[75px] 
            ss:leading-[50px] leading-[45px] tracking-tight 
            md:max-w-[700px] ss:max-w-[600px]'>
              Inspiring Change: <span className='text-secondary'>
              Shaping the Future, One Discovery at a Time.</span>
            </h1>
          </motion.div>

          <motion.div variants={textVariant(0.3)}>
            <p className='md:mt-8 ss:mt-8 mt-5 text-white md:text-[16px]
            ss:text-[17px] text-[13px] md:max-w-[600px] ss:max-w-[480px]
            max-w-[320px] font-medium md:leading-[24px] ss:leading-[24px]
            leading-[18px]'>
              At Lifeline Journals, we believe in the power of 
              knowledge to transform lives and communities. Whether 
              it's a breakthrough in medicine, a new approach in 
              education, or a deeper understanding of science, our 
              journals are a beacon for those who seek to make a 
              difference.
            </p>
          </motion.div>

          <motion.div variants={textVariant(0.8)}>
            <div className='flex flex-row md:mt-8 ss:mt-10 mt-6 md:gap-5 
            ss:gap-5 gap-3 items-center'>
              <a href='/about'>
                <button className='bg-white grow2 md:text-[15px] 
                ss:text-[15px] text-[13px] md:py-3 ss:py-3 py-2.5 
                md:px-14 ss:px-14 px-8 text-primary rounded-xl
                font-medium border-none'>
                  Learn More
                </button>
              </a>
              
              <a href='/contact'>
                <button className='border-[1px] grow2 border-white 
                md:text-[15px] ss:text-[15px] text-[13px] md:py-3 
                ss:py-3 py-2.5 md:px-14 ss:px-14 px-8 text-white 
                rounded-xl font-medium'>
                  Get in Touch
                </button>
              </a>
            </div>  
          </motion.div>
        </div>   
      </div>
    </section>
  );
};

export default SectionWrapper(Hero, 'home');