import aboutImg from './aboutImg.png';
import logo from './logo.png';
import logoalt from './logoalt.png';
import aboutMain from './aboutMain.jpg';
import footerImg from './footerImg.jpg';
import hero2 from './hero2.jpg';
import hero3 from './hero3.jpg';
import heroImg2 from './heroImg2.png';
import heroImage1 from './heroImage1.jpg';
import heroImage2 from './heroImage2.jpg';
import heroImage3 from './heroImage3.jpg';
import heroImage4 from './heroImage4.jpg';
import heroImageMobile1 from './heroImageMobile1.jpg';
import heroImageMobile2 from './heroImageMobile2.jpg';
import heroImageMobile3 from './heroImageMobile3.jpg';
import heroImageMobile4 from './heroImageMobile4.jpg';
import arrow from './arrow.png';
import arrowright from './arrowright.png';
import info from './info.png';

export {
    aboutImg,
    logo,
    logoalt,
    aboutMain,
    footerImg,
    hero2,
    hero3,
    heroImg2,
    heroImage1,
    heroImage2,
    heroImage3,
    heroImage4,
    heroImageMobile1,
    heroImageMobile2,
    heroImageMobile3,
    heroImageMobile4,
    arrow,
    arrowright,
    info,
}

export const heroImages = [
    heroImage1, heroImage2, heroImage3, heroImage4,
];

export const heroImagesMobile = [
    heroImageMobile1, heroImageMobile2, heroImageMobile3, heroImageMobile4,
];