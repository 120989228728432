import { layout } from '../styles';
import { motion } from 'framer-motion';
import { textVariant } from '../utils/motion';
import { SectionWrapper } from '../hoc';
import { BsArrowRightShort } from 'react-icons/bs';

const HeroVolume = ({ archive, journal }) => {  
    const journalName = journal?.name || "Loading...";
    const journalSlug = journal?.slug?.current || "";
    const archiveName = archive.name || "Archive";

    return (
      <section className='w-full'>
          <div className='w-full flex flex-col'>
              <motion.div 
                variants={textVariant()}
                className={`${layout.sectionInfo} justify-center items-center gap-2`}
              >
                <h1 className='text-secondary font-bold md:text-[50px]
                ss:text-[40px] text-[30px] tracking-tight text-center
                md:leading-[60px] ss:leading-[65px] leading-[30px]'>
                    {journalName}
                </h1>

                <div className='flex flex-row items-center md:gap-3 
                ss:gap-2 gap-1 text-white md:text-[16px] ss:text-[15px] 
                text-[12px] justify-center'>
                    <a href='/' className='hover:text-secondary'>
                        Home
                    </a>

                    <BsArrowRightShort className='md:text-[25px] 
                    ss:text-[25px] text-[20px] text-white'
                    />

                    <a href='/journals' className='hover:text-secondary'>
                        Journals
                    </a>

                    <BsArrowRightShort className='md:text-[25px] 
                    ss:text-[25px] text-[20px] text-white'
                    />

                    <a href={`/journals/${journalSlug}`} className='hover:text-secondary'>
                        {journalName}
                    </a>

                    <BsArrowRightShort className='md:text-[25px] 
                    ss:text-[25px] text-[20px] text-white'
                    />

                    <p>{archiveName}</p>
                </div>
              </motion.div>          
          </div>
      </section>  
    )
};

export default SectionWrapper(HeroVolume, '');