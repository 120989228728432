import { SectionWrapper } from '../hoc';
import { motion } from 'framer-motion';
import { textVariant } from '../utils/motion';
import { BiCopyright } from 'react-icons/bi';
import { footerLinks, socialMedia } from '../constants';
import { logoalt } from '../assets';
import React from 'react';

const Footer = () => {
  return (
    <section className='relative w-full md:min-h-[270px] ss:min-h-[390px] 
    min-h-[420px] flex items-center flex-col'>
      <div className='flex items-center w-full relative'>
        <motion.div variants={textVariant()} className='flex md:flex-row 
        ss:flex-row flex-col relative w-full'>
          <div className='flex md:flex-row flex-col flex-start 
          relative w-full md:gap-6 ss:gap-5 md:items-center'>
            <img src={logoalt} alt='logo' className='md:h-[120px] md:w-[120px]
            ss:h-[100px] ss:w-[100px] h-[70px] w-[70px]' />

            <p className='text-white md:text-[15px] ss:text-[15px] 
            text-[12px] md:max-w-[550px] ss:max-w-[300px] max-w-[320px] 
            md:mt-0 ss:mt-3 mt-6 md:leading-[23px] ss:leading-[21px] 
            leading-[19px]'>
              Lifeline Journals is dedicated to the pursuit of knowledge 
              and the advancement of science across a wide spectrum of 
              medical and scientific disciplines. Our journals serve as 
              a platform for researchers, practitioners, and scholars to 
              share their findings and innovations in their respective 
              fields.
            </p>
          </div>
          
          <div className='w-full flex flex-row md:justify-end 
          ss:justify-end'>
            {footerLinks.map((footerLink, index) => (
              <div key={index} className='flex flex-col my-4 md:min-w-[150px]
              md:max-w-[200px] w-full'>
                <h4 className={`font-bold md:text-[16px] ss:text-[16px] 
                text-[14px] text-white 
                  ${index !== footerLinks.length - 1 ? 'md:mr-10 ss:mr-8 mr-8' : 'mr-12'}`}>
                  {footerLink.title}
                </h4>

                <ul className='list-none md:mt-4 ss:mt-3 mt-1 mr-5'>
                  {footerLink.links.map((Link, index) => (
                    <a target='blank' href={Link.route} key={Link.name}>
                      <li className={`md:text-[15px] ss:text-[14px] grow2
                      text-[12px] md:leading-[14px] ss:leading-[14px] leading-[13px]
                      text-white hover:text-secondary cursor-pointer 
                      ${index !== footerLink.links.length - 1 ? 'md:mb-4 ss:mb-2 mb-2' : 'mb-0'}`}>
                        {Link.name}
                      </li>
                    </a>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </motion.div>
      </div>

      <motion.div variants={textVariant()} className='md:absolute ss:absolute 
      flex flex-col w-full bottom-0 md:max-w-[600px] left-0'>
        <div className='flex md:mt-3 ss:mt-3 mt-5 items-center'>
          {socialMedia.map((social, index) => (
            <a target='_blank' href={social.link} rel="noreferrer" 
            key={index}
            className='flex text-white grow2 gap-2 hover:text-secondary'
            >
              {React.createElement(social.Icon, {
                className: `md:w-[20px] ss:w-[20px] w-[18px] h-auto 
                object-contain`
              })}
              <p className='md:text-[16px]
              ss:text-[16px] text-[13px]'>
                contact@lifelinejournals.org
              </p>
            </a>
          ))}
        </div>

        <div className='flex md:mt-3 ss:mt-1 mt-1 items-center'>
          <BiCopyright className='sm:mr-2 mr-1 md:text-[20px] 
          ss:text-[18px] text-[15px] text-white' />

          <p className='md:text-[15px] ss:text-[15px] text-[13px] 
          text-white md:mt-1 ss:mt-1 mt-0.5'>
            2024 | Lifeline Journals | All Rights Reserved.
          </p>
        </div>
      </motion.div>
    </section>
  )
};

export default SectionWrapper(Footer, '');