import { 
    NavbarPages,
    HeroArchiveIssue,
    Sidebar,
    Sidebar2,
    Footer,
    ArchivePubs,
} 
from "../components";
    
import { hero3 } from "../assets";
import { Helmet } from "react-helmet";

import { useEffect, useState } from 'react';
import styles from "../styles";
import { useParams } from 'react-router-dom';
import { client } from '../sanity';
    
const ArchiveIssuePage = () => {
    const { slug, volumeSlug, itemSlug } = useParams();
    const [journal, setJournal] = useState(null);
    const [archive, setArchive] = useState(null);
    const [issue, setIssue] = useState(null);

    useEffect(() => {
        const query = `
        *[_type == "journals" && slug.current == $slug]{
            name,
            description,
            image,
            slug,
            issn,
            currentIssue->{
                name,
                slug,
                papers[]->{
                    heading,
                    authors,
                    abstract,
                    pages,
                    pdfFile {
                        asset-> {
                            url
                        }
                    }
                },
            },
            archives[]->{
                name,
                slug,
                issue[]->{
                    name,
                    slug,
                    papers[]->{
                        heading,
                        authors,
                        abstract,
                        pages,
                        pdfFile {
                            asset-> {
                                url
                            }
                        }
                    },
                },
            },
            indexings,
            editors[]->{
                name,
                school,
                profile
            },
            contact,
            frequency,
            instructions,
            processing,
        }
        `;
        client.fetch(query, { slug, volumeSlug, itemSlug })
            .then((data) => {
                if (!data) {
                    console.error('No data found for the given slugs.');
                } else {
                    setJournal(data[0]);
                    // Find the specific archive that matches the volumeSlug
                    const foundArchive = data[0].archives.find(archive => archive.slug.current === volumeSlug);
                    setArchive(foundArchive);
                    // Find the specific issue that matches the itemSlug within the found archive
                    const foundIssue = foundArchive?.issue.find(issue => issue.slug.current === itemSlug);
                    setIssue(foundIssue);
                }
            })
            .catch((error) => console.error('Error fetching journal:', error));
    }, [slug, volumeSlug, itemSlug]);

    if (!journal || !archive || !issue) {
        return <div>Loading...</div>;
    }

  
      return (
        <div className="font-instrument-sans">
          <Helmet>
            <title>{`${journal?.name || "Journal"} | ${archive?.name || "Archive"} | Lifeline Journals`}</title>
            <meta name="description" content="Join a community dedicated to advancing science, enhancing healthcare, and making a lasting impact on the world." />
          </Helmet>
  
          <div>
            <NavbarPages />
            <div className='relative flex items-center justify-center w-full 
            md:max-h-[300px] ss:max-h-[300px] max-h-[200px] md:mt-20 ss:mt-16 
            mt-16'>
              <img src={hero3} alt='hero3'
              className='w-[120rem] md:h-[200px] ss:h-[150px]
              h-[120px]' />
              <div className='absolute w-full md:pb-8 ss:pb-8 pb-5'>
                <HeroArchiveIssue archive={archive} issue={issue} journal={journal} />
              </div>
            </div>

            <div className='aboutMain'>
                <div className={`${styles.padding} max-w-[78rem] mx-auto flex 
                hidden md:flex`}>
                    <div>
                        <Sidebar journal={journal} />
                    </div>

                    <div className='ml-20 w-full'>
                        <ArchivePubs issues={issue.papers} issue={issue} archive={archive} journal={journal} />
                    </div>
                </div>

                <div className='flex md:hidden flex flex-col'>
                    <div>
                        <ArchivePubs issues={issue.papers} issue={issue} archive={archive} journal={journal} />
                    </div>

                    <div>
                        <Sidebar2 journal={journal}/>
                    </div>
                </div>
            </div>
    
            <div className='footer'>
              <Footer />
            </div>
          </div>
        </div>
      );
    };
    
export default ArchiveIssuePage;