import { useState, useEffect, useRef } from 'react';
import { SectionWrapper } from "../hoc";
import { motion } from 'framer-motion';
import { fadeIn } from '../utils/motion';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { client, urlFor } from '../sanity';
import { BsArrowRightShort } from 'react-icons/bs';

const JournalCard = ({ index, name, description, slug, image }) => {
    return (
      <div className='xs:w-[320px] w-full grow2'>
        <motion.div
          variants={fadeIn('right', 'spring', 0.5 * index, 0.75)}
          className='w-full p-[1.5px] cursor-pointer rounded-xl
          shadow-md hover:shadow-xl'
        >
          <a href={`/journals/${slug.current}`}>
            <div options={{ max: 45, scale: 1, speed: 450 }}
              className='bg-white p-1 md:pb-10 ss:pb-10 pb-8 md:gap-5 
              ss:gap-5 gap-5 flex justify-evenly items-center flex-col
              rounded-xl'
            >
              {image ? (
                <img 
                  src={urlFor(image)} 
                  alt={name}
                  className='w-30 h-30 object-contain rounded-t-xl' 
                />
              ) : (
                <div className='rounded-t-xl flex items-center justify-center'>
                  <p className='text-main text-[14px]'>No Image</p>
                </div>
              )}

              <h2
                className='text-primary md:text-[19px] ss:text-[18px] text-[18px] 
                font-bold md:leading-[25px] ss:leading-[20px] leading-[16px] 
                text-center md:max-w-[270px]'
              >
                {name}
              </h2>

              <h3
                className='text-textalt md:text-[14px] ss:text-[14px] 
                text-[14px] text-center md:leading-[18px] ss:leading-[18px]
                leading-[17px] md:max-w-[290px] ss:max-w-[400px]
                max-w-[300px]'
              >
                {description}
              </h3>
            </div>
          </a>
        </motion.div>
      </div>
    )
  };

const Journals = () => {
  const [journals, setJournals] = useState([]);
  const carouselRef = useRef(null);
  const itemRef = useRef(null);

  useEffect(() => {
    const query = `
      *[_type == "journals"] | order(_createdAt asc) {
        name,
        description,
        image,
        slug,
      }
    `;

    client.fetch(query)
      .then((data) => setJournals(data))
  }, []);

  const scrollLeft = () => {
    if (carouselRef.current && itemRef.current) {
      const itemWidth = itemRef.current.clientWidth + 24;
      carouselRef.current.scrollBy({
        left: -itemWidth,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current && itemRef.current) {
      const itemWidth = itemRef.current.clientWidth + 24;
      carouselRef.current.scrollBy({
        left: itemWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className="md:min-h-[750px] ss:min-h-[500px] min-h-[1250px] 
    flex items-center">
      <div className='items-center w-full flex flex-col'>
        <motion.div variants={fadeIn('up', 'spring', 0.3)}
        className="flex justify-between items-center w-full md:gap-12
        ss:gap-8 gap-5 md:mb-14 ss:mb-12 mb-6">
          <h1 className="text-primary md:text-[40px] ss:text-[35px] 
          text-[25px] font-bold">
            Our Journals
          </h1>

          <div className="flex-grow">
            <div className='bg-secondary w-full h-[3px] rounded-full' />
          </div>
        </motion.div>

        <motion.div className='flex items-center relative 
        justify-center w-full'>
          <button 
            className='absolute left-0 bg-main text-white p-3 
            rounded-full opacity-90 hover:opacity-100 navsmooth grow2
            hidden md:flex'
            onClick={scrollLeft}
          >
            <HiChevronLeft size={20} />
          </button>

          <div ref={carouselRef} className='flex overflow-hidden w-full
          md:w-[1060px] md:h-[550px] ss:h-[1050px] h-[1100px] mobjor'>
            <div className='flex md:flex-row flex-col 
            md:gap-12 ss:gap-8 gap-8'>
              {journals.map((journal, index) => (
                <div key={journal.name} 
                ref={index === 0 ? itemRef : null}
                >
                  <JournalCard 
                    index={index} 
                    {...journal} 
                  />
                </div>
              ))}
            </div>
          </div>

          <button 
            className='absolute right-0 bg-main text-white p-3 
            rounded-full opacity-90 hover:opacity-100 navsmooth grow2
            hidden md:flex'
            onClick={scrollRight}
          >
            <HiChevronRight size={20} />
          </button>
        </motion.div>

        <a href='/journals'
          className='flex flex-row md:mt-10 ss:mt-10 mt-6 
          justify-center items-center gap-1 cursor-pointer 
          grow2 md:mb-0 ss:mb-5 mb-0'
        >
          <p className='font-medium text-primary md:text-[16px]
          ss:text-[15px] text-[13px]' 
          >
            View All
          </p>

          <BsArrowRightShort className='text-[25px] text-primary' />
        </a>
      </div>
    </section>
  );
};

export default SectionWrapper(Journals, 'journals');