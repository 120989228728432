import { SectionWrapper } from "../hoc";
import { motion } from 'framer-motion';
import { fadeIn, textVariant } from '../utils/motion';
import { urlFor } from '../sanity';

const HeroJournalEach = ({ journal }) => {

    return (
        <section className="w-full md:min-h-[550px] ss:min-h-[200px] 
        min-h-[500px] flex flex-col"
        >
            <div className='flex md:flex-row ss:flex-row flex-col md:gap-10 
                ss:gap-10 gap-6 w-full'
            >
                <motion.div 
                    variants={fadeIn('left', 'spring', 0.3)}>
                    <img 
                        src={urlFor(journal.image)}
                        alt={journal.name}
                        className='md:h-auto w-[600px]'
                    />
                </motion.div>

                <motion.div variants={textVariant()}
                className=''>
                    <p className='text-main md:text-[16px] ss:text-[16px] 
                    text-[13px] md:leading-[21px] ss:leading-[21px] 
                    leading-[18px] md:text-justify ss:text-justify'>
                        {journal.description}
                    </p>
                </motion.div>
            </div>

            <motion.div variants={fadeIn('down', 'spring', 0.3)}
            className="md:mt-12 ss:mt-8 mt-5">
                <div className='bg-main w-full h-[1px]
                rounded-full' />
            </motion.div>

            <motion.div variants={textVariant()}
            className='md:mt-8 ss:mt-6 mt-5'>
                <p className='text-main md:text-[17px] ss:text-[16px] 
                text-[13px] font-bold'>
                    E-ISSN: <span className="font-light">{journal.issn}</span>
                </p>
            </motion.div>
        </section>
    );
};

export default SectionWrapper(HeroJournalEach, '');