import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { arrow, arrowright, info } from '../assets';
import { motion, AnimatePresence } from 'framer-motion';


const Modal = ({ onClose, name, school, profileLink }) => {
  const modalRef = useRef(null);

  const enableScroll = () => {
    document.body.style.overflow = 'auto';
    document.body.style.top = '0';
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
        enableScroll();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <AnimatePresence>
      <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center
      bg-black bg-opacity-80 z-50">
        <motion.div 
        initial={{ y: 0, opacity: 0.7 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 10, opacity: 0 }}
        transition={{ duration: 0.1 }}
        ref={modalRef} 
        className="bg-white p-10 rounded-md shadow-xl flex flex-col 
        justify-center w-1/3 h-auto items-center m-6">
          <div className='flex flex-col w-full gap-3'>
            <h1 className='text-primary text-[25px] font-bold'>
              {name}
            </h1>

            <div className="flex-1 relative items-center justify-center">
              <div className='bg-textalt w-full h-[1px]' />
            </div>

            <p className='text-maintext text-[16px] leading-[20px]'>
                {school}
            </p>

            <a
              href={profileLink}
              target='_blank'
              rel='noreferrer'
              className='text-[15px] text-maintext hover:text-secondary'
              >
                {profileLink}
            </a>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

const Sidebar = ({ journal }) => {
  const navigate = useNavigate();
  const [expandedItem, setExpandedItem] = useState(null);

  const [modalInfo, setModalInfo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const disableScroll = () => {
    setScrollPosition(window.pageYOffset);
    document.body.style.overflow = 'hidden';
    document.body.style.top = `-${scrollPosition}px`;
  };

  const handleSideItemClick = (link) => {
    setExpandedItem(expandedItem === link.id ? null : link.id);
  };

  const handleSubItemClick = (route) => {
    navigate(route);
  };

  const handleModalOpen = (info) => {
    setModalInfo(info);
    setModalOpen(true);
    disableScroll();
  };

  const handleModalClose = () => {
    setModalInfo(null);
    setModalOpen(false);
  };

  const sideLinks = [
    {
      id: "current",
      title: "Current Issue",
      links: journal.currentIssue ? [
        {
          name: journal.currentIssue.name,
          route: `/journals/${journal.slug.current}/${journal.currentIssue.slug.current}`,
        },
      ] : [],
    },
    {
      id: "archives",
      title: "Archives",
      links: journal.archives?.map(volume => {
        if (!volume.slug || !journal.slug) {
          console.error('Missing slug in archives:', volume);
          return null;
        }
    
        return {
          name: volume.name,
          route: `/journals/${journal.slug.current}/archives/${volume.slug.current}`,
        };
      }).filter(Boolean) || [],
    },
    {
      id: "indexings",
      title: "Indexings",
      links: journal.indexings?.map((indexing, i) => ({
        name: indexing,
        route: null,
      })) || [],
    },
    {
      id: "editors",
      title: "Editors",
      links: journal.editors?.map(editor => ({
        name: editor.name,
        school: editor.school,
        profileLink: editor.profile,
        route: null,
      })) || [],
      special: true,
    },
    {
      id: "frequency",
      title: "Frequency: 6",
      links: [
        {
          name: journal.frequency,
          route: null,
        },
      ],
    },
    {
      id: "instructions",
      title: "Author's Instructions",
      links: [
        {
          name: journal.instructions,
          route: null,
        },
      ],
    },
    {
      id: "processing",
      title: "Article Processing Charge",
      links: [
        {
          name: journal.processing,
          route: null,
        },
      ],
    },
    {
      id: "contact",
      title: "Contact",
      links: [
        {
          name: journal.contact,
          route: `mailto:${journal.contact}`,
        },
      ],
    },
  ];


  return (
    <div className='flex items-center hidden md:flex w-full'>

      {modalOpen && (
        <Modal 
          onClose={handleModalClose}
          name={modalInfo.name}
          school={modalInfo.school}
          profileLink={modalInfo.profileLink}
        />
      )}

      <div className="w-full flex justify-between items-center py-8">
        <div className="flex flex-col items-center w-full">
          <ul className="list-none flex flex-col gap-5 w-[350px]">
            {sideLinks.map((link) => (
              <li
                key={link.id}
                className='grow4 text-[16px] border-textalt 
                border-[0.5px] text-decoration-none
                text-textalt list-item bg-white'
              >
                <div className="flex gap-6 items-center justify-between
                cursor-pointer px-6 py-3"
                onClick={(e) => handleSideItemClick(link, e)}>
                  {link.title}
                  <span>
                    <img
                      src={arrow}
                      alt={link.title}
                      className="h-[5px] w-auto"
                    />
                  </span>
                </div>

                {expandedItem === link.id && (
                  <div className='px-6 pb-3'>
                    {link.links && link.links.length > 0 && (
                      <ul>
                        {link.links.map((submenuItem, index) => (
                          <li key={index}>
                            <a href={submenuItem.route} className="block 
                            text-main py-[3px]"
                            onClick={(e) => {
                              e.preventDefault();
                              if (submenuItem.route && submenuItem.route.startsWith('mailto:')) {
                                window.location.href = submenuItem.route;
                              } else if (submenuItem.route) {
                                handleSubItemClick(submenuItem.route);
                              }
                            }}
                            >
                              <div className='flex items-center gap-3'>
                                {submenuItem.name}

                                {link.special && (
                                  <img src={info} alt='info'
                                    className='h-[14px] w-auto cursor-pointer'
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleModalOpen(submenuItem);
                                    }}
                                  />               
                                )}
                              </div>
                              
                              <span className="block text-maintext text-[14px]
                              leading-[18px] font-normal"
                              >
                                {submenuItem.school}
                              </span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </li>
            ))}

            <li className='grow4 text-[16px] px-6 py-3 border-textalt 
                border-[0.5px] text-decoration-none cursor-pointer 
                text-white list-item bg-primary mt-6'>
              <button 
                onClick={(e) => {
                  e.preventDefault();
                }}
                className='flex gap-6 items-center w-full justify-between'
              >
                Submit Manuscript
                <img src={arrowright} alt='submit'
                  className='h-[11px] w-auto'
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;