import { SectionWrapper } from "../hoc";
import { layout } from '../styles';
import { motion } from 'framer-motion';
import { fadeIn, textVariant } from '../utils/motion';
import { aboutImg } from '../assets';

const About = () => {

    return (
        <section className="w-full md:min-h-[550px] ss:min-h-[650px] 
        min-h-[600px] flex items-center"
        >
            <div className='items-center w-full flex-col md:gap-5 
            ss:gap-5 gap-10'
            >
                <motion.div variants={fadeIn('up', 'spring', 0.3)}
                className="flex flex-row items-center w-full md:mb-12 ss:mb-14 
                mb-8">
                    <h1 className="text-primary font-bold md:text-[40px]
                    ss:text-[35px] text-[25px] md:mr-14 ss:mr-6 mr-5
                    md:leading-[2px] ss:leading-[2px] leading-[40px]">
                       About Lifeline Journals
                    </h1>
                    <motion.div variants={fadeIn('down', 'spring', 0.3)}
                    className="flex-1 relative items-center justify-center">
                        <div className='bg-secondary w-full h-[3px]
                        rounded-full' />
                    </motion.div>
                </motion.div>
                
                <motion.div variants={fadeIn('down', 'spring', 0.3)}
                className="flex md:flex-row flex-col md:gap-20 
                ss:gap-10 gap-12 w-full aboutimage">
                    <motion.div variants={textVariant()}
                    className={`${layout.sectionInfo}`}>
                        <p className='text-main md:text-[16px] ss:text-[15px] 
                        text-[13px] md:max-w-[700px] 
                        md:leading-[23px]'>
                            Lifeline Journals is your gateway to the 
                            latest research and developments across the 
                            medical and scientific spectrum. Explore our 
                            diverse range of peer-reviewed journals, 
                            where leading experts share groundbreaking 
                            discoveries that drive innovation and improve 
                            global health. Join a community dedicated to 
                            advancing science, enhancing healthcare, and 
                            making a lasting impact on the world.
                        </p>

                        <a href='/about' 
                        className="md:mt-8 ss:mt-8 mt-6">
                            <button className='bg-primary grow md:text-[15px] 
                            ss:text-[15px] text-[13px] md:py-3 ss:py-3 py-2.5 
                            md:px-14 ss:px-14 px-8 text-white rounded-xl
                            font-medium border-none'
                            >
                                Learn More
                            </button>
                        </a>
                    </motion.div>

                    <motion.div 
                        variants={fadeIn('left', 'spring', 0.3)}>
                            <img src={aboutImg} alt='about'
                            className='md:h-[350px] ss:h-[300px] w-auto 
                            rounded-3xl'
                        />
                    </motion.div>
                </motion.div>
            </div>
        </section>
    );
};

export default SectionWrapper(About, 'about');