import { 
  NavbarAlt3,
  Hero4,
  Contact,
  Footer } from "../components";
  
import { hero2 } from "../assets";
import { Helmet } from "react-helmet";
  
  const ContactPage = () => {

    return (
      <div className="font-instrument-sans">
        <Helmet>
          <title>Contact Us | Lifeline Journals</title>
          <meta name="description" content="Get in touch with us" />
        </Helmet>

        <div>
          <NavbarAlt3 />
          <div className='relative flex items-center justify-center w-full 
          md:max-h-[300px] ss:max-h-[300px] max-h-[200px] md:mt-20 ss:mt-16 
          mt-16'>
            <img src={hero2} alt='hero4'
            className='w-[120rem] md:h-[200px] ss:h-[150px]
            h-[120px]' />
            <div className='absolute w-full md:pb-8 ss:pb-8 pb-5'>
              <Hero4 />
            </div>
          </div>

          <Contact />
  
          <div className='footer'>
            <Footer />
          </div>
        </div>
      </div>
    );
  };
  
  export default ContactPage;