import { GrMail } from 'react-icons/gr';

export const socialMedia = [
    {
        id: "social-media-2",
        Icon: GrMail,
        link: "mailto:contact@lifelinejournals.org"
    }
];
    
export const navLinks = [
    {
        id: "home",
        title: "Home",
        route: "/",
    },
    {
        id: "about",
        title: "About Us",
        route: "/about",
    },
    {
        id: "journals",
        title: "Journals",
        route: "/journals",
    },
    {
        id: "",
        title: "Contact",
        route: "/contact",
        special: true,
    },
];

export const footerLinks = [
    {
        title: "Company",
        links: [
        {
            name: "About",
            route: "/about",
        },
        {
            name: "Journals",
            route: "/journals",
        },
        {
            name: "Contact",
            route: "/contact",
        }, 
        ],
    },
    
    {
        title: "Legal",
        links: [
        {
            name: "Cookies",
            link: "",
        },
        {
            name: "Privacy Policy",
            link: "",
        },
        {
            name: "Security",
            link: "",
        },
        {
            name: "Terms of Use",
            link: "",
        },
        ],
    },
];